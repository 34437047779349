<template>
  <div class="app-content">
    <app-bar class="app-content__header" v-model="activeNav" />
    <main class="app-content__content">
      <router-view />
    </main>
    <TheFooter />
    <v-top-scroll />
  </div>
</template>

<script>
import appBar from "@/components/layout/appBar";
import TheFooter from "@/components/layout/TheFooter.vue";
import VTopScroll from "@/components/ui/v-top-scroll.vue";

export default {
  name: "App",
  components: {
    VTopScroll,
    TheFooter,
    appBar,
  },
  data() {
    return {
      activeNav: false,
    };
  },
};
</script>

<style lang="scss">
.app-content {
  &__nav {
    width: 30%;
    @include below($desktopSml) {
      width: 100%;
    }
  }
  &__content {
    padding: 16px 48px;
    min-height: 80vh;
    @include below($tablet) {
      padding: 22px 16px;
    }
  }
}
</style>
