<template>
  <transition name="fade">
    <div class="v-top-scroll" v-scroll-to="{ el: 'body' }" v-show="buttonShow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <path
          d="M31.1668 25.6667L22.0002 16.5001L12.8335 25.6667"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  name: "v-top-scroll",
  data() {
    return {
      buttonShow: false,
    };
  },
  mounted: function () {
    this.checkScrollPosition();
    window.addEventListener("scroll", this.checkScrollPosition);
  },
  methods: {
    checkScrollPosition() {
      this.buttonShow = window.pageYOffset > 500;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-top-scroll {
  color: var(--gray-100);
  padding: 6px;
  border-radius: var(--ui-radius);
  border: 1px solid var(--gray-400);
  background: var(--blue-600);
  position: fixed;
  right: 32px;
  bottom: 32px;
  cursor: pointer;
  transition: all 0.2s ease;
  @include below($tablet) {
    right: 16px;
    bottom: 16px;
  }
  &:hover {
    color: var(--main-white);
    background: var(--blue-800);
  }
  svg {
    width: 44px;
    height: 44px;
    @include below($tablet) {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
