<template>
  <component
    :is="tag"
    :class="[
      bem('v-btn', `${size} ${variant}`),
      { fullWidth, active, disabled: disabled || loading, square },
      `place-${textPosition}`,
    ]"
    :href="href"
    :to="{ name: to }"
  >
    <slot />
  </component>
</template>

<script setup>
import { computed, defineProps } from "vue";
import bem from "@/core/bem";

const props = defineProps({
  to: String,
  href: String,
  variant: {
    type: String,
    default: "filled",
  },
  size: {
    type: String,
    default: "default",
  },
  fullWidth: Boolean,
  square: Boolean,
  disabled: Boolean,
  active: Boolean,
  textPosition: {
    type: String,
    default: "center",
  },
});

const tag = computed(() => {
  if (props.to) return "router-link";
  if (props.href) return "a";
  return "button";
});
</script>

<style lang="scss" scoped>
.v-btn {
  $spacing: 8px;
  font-family: var(--main-font), sans-serif;
  border-radius: var(--ui-radius);
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  outline: 0;
  vertical-align: middle;
  white-space: normal;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  user-select: none;
  height: $spacing * 6;
  padding: 10px 32px;
  font-weight: 500;
  font-size: 14px;
  &.disabled {
    pointer-events: none;
  }
  &.fullWidth {
    display: flex;
    width: 100%;
    padding-right: $spacing;
    padding-left: $spacing;
  }
  &.place-left {
    justify-content: flex-start;
    text-align: left;
  }
  &.place-right {
    justify-content: flex-end;
    text-align: right;
  }
  &.square {
    padding-right: $spacing;
    padding-left: $spacing;
  }
  // sizes
  &--small {
    height: $spacing * 5;
    padding: 6px 12px;
  }
  &--large {
    padding: 10px 86px;
  }
  // variants
  &--ghost {
    color: var(--main-color);
    border: 0.5px solid var(--main-color);
    background: transparent;
    transition: color, background, border-color 0.2s ease;
    &:hover {
      background: var(--gray-600);
      border-color: var(--gray-600);
    }
    &.disabled {
      border: 0.5px solid var(--gray-400);
      color: var(--gray-200);
    }
  }
  &--ghost-white {
    color: var(--gray-100);
    border: 0.5px solid var(--gray-200);
    background: transparent;
    transition: color, background, border-color 0.2s ease;
    &:hover {
      background: var(--gray-600);
      border-color: var(--gray-600);
    }
    &.disabled {
      border: 0.5px solid var(--gray-400);
      color: var(--gray-200);
    }
  }
  &--filled {
    color: var(--main-white);
    background: var(--main-color);
    border: 0.5px solid var(--main-color);
    transition: color, background 0.2s ease;
    &:hover {
      border-color: var(--dark-orange);
      background: var(--dark-orange);
    }
    &.disabled {
      border: 0.5px solid var(--gray-400);
      color: var(--gray-200);
      background: transparent;
    }
  }
  &--text {
    background: transparent;
    border: 0.5px solid transparent;
    color: var(--gray-100);
    transition: color, background 0.2s ease;
    &:hover {
      border-color: var(--blue-600);
      color: var(--main-white);
      background: var(--blue-600);
    }
    &.disabled {
      border: 0.5px solid var(--gray-400);
      color: var(--gray-200);
      background: transparent;
    }
    &.active,
    &.router-link-active {
      border-color: var(--blue-600);
      color: var(--main-color);
      background: var(--blue-600);
    }
  }
  &--text-main-hover {
    background: transparent;
    border: 0.5px solid transparent;
    color: var(--gray-200);
    transition: color, background 0.2s ease;
    &:hover {
      border-color: var(--blue-600);
      color: var(--main-color);
      background: var(--blue-600);
    }
    &.disabled {
      border: 0.5px solid var(--gray-400);
      color: var(--gray-200);
      background: transparent;
    }
    &.active {
      border-color: var(--blue-600);
      color: var(--main-color);
      background: var(--blue-600);
    }
  }
  &--text-main {
    background: transparent;
    border: 0.5px solid transparent;
    color: var(--main-color);
    transition: color 0.2s ease;
    &:hover {
      color: var(--dark-orange);
    }
    &.disabled {
      color: var(--gray-200);
    }
    &.active {
      border-color: var(--blue-600);
      color: var(--main-color);
      background: var(--blue-600);
    }
  }
  &--gradient {
    z-index: 1;
    position: relative;
    color: var(--main-white);
    background: transparent;
    overflow: hidden;
    border: 1px solid var(--main-color);
    background-size: 200%;
    transition: border-color 0.2s ease-in-out;
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: var(--main-gradient);
      z-index: -1;
    }
    &::after {
      opacity: 0;
      background: var(--dark-orange);
      transition: opacity 0.2s ease-in-out;
    }
    &:hover {
      border-color: var(--dark-orange);
    }
    &:hover::after {
      opacity: 1;
    }
  }
}
</style>
