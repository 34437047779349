import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home"),
  },
  {
    path: "/create-wallet",
    name: "create-wallet",
    component: () => import("@/views/create-wallet.vue"),
  },
  {
    path: "/connect-wallet",
    name: "connect-wallet",
    component: () => import("@/views/connect-wallet.vue"),
  },
  {
    path: "/restore",
    name: "restore",
    component: () => import("@/views/restore.vue"),
  },
  {
    path: "/transfer",
    name: "transfer",
    component: () => import("@/views/transfer.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("@/views/withdraw.vue"),
  },
  {
    path: "/placing-order",
    name: "order",
    component: () => import("@/views/order.vue"),
  },
  {
    path: "/trade",
    name: "trade",
    component: () => import("@/views/trade.vue"),
  },
];
const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

export default router;
