<template>
  <div class="app-bar">
    <router-link to="/" class="app-bar__link">
      <LogoSvg />
    </router-link>
    <a :href="TESTNET_LINK" target="_blank" class="app-bar__back">
      <ReverseIcon />
      Back to Testnet
    </a>
  </div>
</template>

<script>
import LogoSvg from "@/components/icons/LogoSvg";
import ReverseIcon from "@/components/icons/ReverseIcon.vue";
import { TESTNET_LINK } from "@/config";

export default {
  name: "AppBar",
  components: {
    ReverseIcon,
    LogoSvg,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      TESTNET_LINK,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 10px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--common-bg);
  border-bottom: 1px solid var(--gray-600);
  box-shadow: 0 -5px 5px 0 rgba(16, 25, 39, 0.3);
  @include below($tablet) {
    padding: 5px 16px;
    background: var(--blue-800);
    box-shadow: 0 5px 5px 0 rgba(16, 25, 39, 0.3);
    border-bottom: none;
  }
  &__back {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 12px;
    &:hover {
      background: var(--blue-600);
      border-radius: var(--ui-radius);
    }
  }
}
</style>
