import { createApp } from "vue";
import App from "./App.vue";
import store from "./router";
import VueScrollTo from "vue-scrollto";
import "@/assets/scss/top.scss";
let app = createApp(App);

// Modules
app.use(store);

// Components
import VExpandPanel from "@/components/ui/v-expand-panel";

app.component("v-expand-panel", VExpandPanel);
app.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
app.config.devtools = true;
app.mount("#app");
