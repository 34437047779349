<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="152" height="40" viewBox="0 0 152 40" fill="none">
    <g clip-path="url(#clip0_5687_1414)">
      <path d="M36.5895 22.2403C36.5895 25.1233 35.4614 27.8285 33.4096 29.8655L23.5343 39.6818L21.878 38.0374L31.7652 28.2329C33.3738 26.6359 34.2616 24.504 34.2616 22.2403C34.2616 19.9885 33.3738 17.8566 31.7652 16.2596L22.5852 7.14552L8.82278 20.8107C8.54672 21.0848 8.54672 21.5263 8.82278 21.7989L15.0154 27.947L22.251 20.7633L23.9073 22.3959L15.8435 30.4018C15.3989 30.83 14.6319 30.8433 14.1991 30.4018L10.8268 27.0655L7.17838 23.4329C6.6024 22.8611 6.29053 22.11 6.29053 21.3011C6.29053 20.5026 6.6024 19.7411 7.17838 19.1811L21.7586 4.69367C22.2032 4.26552 22.9702 4.26552 23.403 4.69367L33.4111 14.63C35.4614 16.6655 36.5895 19.3692 36.5895 22.2403Z" fill="url(#paint0_linear_5687_1414)"/>
      <path d="M30.1108 22.4079C30.1108 23.8967 29.5349 25.279 28.4784 26.3279L15.662 39.0523C15.2307 39.4938 14.4622 39.4938 14.0176 39.0523L2.12628 27.2582C0.578874 25.7219 -0.26123 23.6716 -0.26123 21.5041C-0.26123 19.3367 0.578874 17.2982 2.12628 15.7619L14.0176 3.95599L15.65 5.60044L3.77067 17.3945C2.66645 18.4908 2.06659 19.956 2.06659 21.5041C2.06659 23.0641 2.66645 24.519 3.77067 25.6138L14.8457 36.5975L26.834 24.6953C27.4458 24.0879 27.7815 23.2775 27.7815 22.4079C27.7815 21.5501 27.4458 20.7278 26.834 20.1204L22.4425 15.7723L16.7542 21.4316L17.5347 22.2064L15.8783 23.8508L13.4908 21.4686L13.5027 21.4567L13.4669 21.4212L21.6262 13.3204C22.0709 12.8671 22.814 12.8671 23.2706 13.3204L27.0981 17.1204L27.986 18.0138L28.4784 18.4908C29.5349 19.5367 30.1108 20.9308 30.1108 22.4079Z" fill="url(#paint1_linear_5687_1414)"/>
      <path d="M46.9875 13.1174C44.497 13.1174 42.7153 13.6004 41.6917 14.553C40.674 15.5011 40.1577 17.1634 40.1577 19.4937V23.8419C40.1577 26.1722 40.674 27.833 41.6917 28.7811C42.7153 29.7352 44.497 30.2182 46.9875 30.2182H57.2746C58.2461 30.2182 58.9459 29.9574 59.4174 29.4226C59.8919 28.8834 60.1337 27.95 60.1337 26.6478V20.7574C60.1337 20.4182 60.0128 20.2967 59.6696 20.2967H49.0512C48.8587 20.2967 48.7915 20.3397 48.7915 20.5797V22.4759C48.7915 22.7159 48.8602 22.7589 49.0512 22.7589H56.5121C56.8807 22.7589 57.118 23.0048 57.118 23.3856V25.433C57.118 26.0671 57.0001 26.5174 56.7569 26.8108C56.5047 27.1174 56.0958 27.273 55.5452 27.273H47.1412C45.737 27.273 44.7925 27.0078 44.2538 26.4641C43.7166 25.9204 43.454 24.9559 43.454 23.513V19.873C43.454 18.4463 43.7151 17.4893 44.2538 16.9471C44.7925 16.4019 45.737 16.1367 47.1412 16.1367H59.8994C60.0919 16.1367 60.1591 16.0937 60.1591 15.8537V13.3767C60.1591 13.1708 60.1053 13.1174 59.8994 13.1174H46.9875Z" fill="url(#paint2_linear_5687_1414)"/>
      <path d="M85.5291 30.067L77.1057 14.8893C76.7222 14.187 76.3372 13.7218 75.9418 13.4715C75.5583 13.233 75.0539 13.1144 74.4421 13.1144H72.9306C72.4381 13.1144 72.0188 13.233 71.6592 13.4833C71.2877 13.7337 70.9027 14.2107 70.5192 14.8893L62.1793 30.0789C62.1435 30.1381 62.1196 30.1856 62.1196 30.2093C62.1435 30.2093 62.1793 30.2211 62.239 30.2211H65.5517C65.768 30.2211 65.8277 30.1381 65.8635 30.0656L67.7109 26.6226H80.0349L81.9315 30.0419C81.9792 30.1367 82.0389 30.2211 82.2553 30.2211H85.4948C85.5426 30.2211 85.5784 30.2093 85.5903 30.2093C85.5769 30.1856 85.5649 30.1381 85.5291 30.067ZM70.0014 23.6937L73.7572 16.7248L73.4931 16.5826L73.7692 16.713L73.9497 16.7248L77.7533 23.6937H70.0014Z" fill="url(#paint3_linear_5687_1414)"/>
      <path d="M87.475 13.1174C87.2318 13.1174 87.1885 13.1856 87.1885 13.3767V23.8434C87.1885 26.1737 87.7048 27.8345 88.7239 28.7826C89.7476 29.7367 91.5293 30.2197 94.0182 30.2197H101.352C103.841 30.2197 105.625 29.7367 106.648 28.7826C107.666 27.8345 108.182 26.1722 108.182 23.8434V13.3767C108.182 13.1708 108.128 13.1174 107.922 13.1174H105.147C104.938 13.1174 104.887 13.1678 104.887 13.3767V23.4641C104.887 24.9056 104.631 25.87 104.104 26.4137C103.571 26.9604 102.631 27.2256 101.227 27.2256H94.1734C92.7693 27.2256 91.8247 26.9604 91.286 26.4152C90.7488 25.8715 90.4877 24.9056 90.4877 23.4641V13.3767C90.4877 13.1708 90.434 13.1174 90.2281 13.1174H87.475Z" fill="url(#paint4_linear_5687_1414)"/>
      <path d="M112.124 27.3011C111.883 27.3011 111.839 27.3693 111.839 27.5589V29.9604C111.839 30.1515 111.883 30.2181 112.124 30.2181H126.232C127.637 30.2181 128.701 29.873 129.395 29.1915C130.087 28.51 130.44 27.5693 130.44 26.3944V24.3722C130.44 23.0626 130.087 22.0493 129.394 21.3589C128.7 20.67 127.654 20.3204 126.282 20.3204H116.732C115.685 20.3204 115.109 19.7115 115.109 18.6063V17.747C115.109 16.6419 115.695 16.0344 116.758 16.0344H129.516C129.722 16.0344 129.776 15.9811 129.776 15.7752V13.3737C129.776 13.1678 129.722 13.1144 129.516 13.1144H116.02C114.615 13.1144 113.551 13.4596 112.857 14.1411C112.154 14.8315 111.812 15.747 111.812 16.9381V18.7841C111.812 20.0952 112.165 21.1085 112.859 21.7974C113.552 22.4863 114.598 22.8359 115.97 22.8359H125.518C126.566 22.8359 127.142 23.4448 127.142 24.55V25.5856C127.142 26.6907 126.555 27.2996 125.491 27.2996H112.124V27.3011Z" fill="url(#paint5_linear_5687_1414)"/>
      <path d="M133.948 27.3011C133.705 27.3011 133.662 27.3693 133.662 27.5589V29.9604C133.662 30.1515 133.705 30.2181 133.948 30.2181H148.055C149.461 30.2181 150.525 29.873 151.219 29.1915C151.911 28.51 152.263 27.5693 152.263 26.3944V24.3722C152.263 23.0626 151.911 22.0493 151.217 21.3589C150.523 20.67 149.477 20.3204 148.106 20.3204H138.557C137.51 20.3204 136.934 19.7115 136.934 18.6063V17.747C136.934 16.6419 137.52 16.0344 138.583 16.0344H151.341C151.547 16.0344 151.601 15.9811 151.601 15.7752V13.3737C151.601 13.1678 151.547 13.1144 151.341 13.1144H137.844C136.44 13.1144 135.376 13.4596 134.681 14.1411C133.978 14.8315 133.636 15.7456 133.636 16.9381V18.7841C133.636 20.0952 133.988 21.1085 134.682 21.7974C135.376 22.4863 136.422 22.8359 137.793 22.8359H147.344C148.39 22.8359 148.967 23.4448 148.967 24.55V25.5856C148.967 26.6907 148.381 27.2996 147.318 27.2996H133.948V27.3011Z" fill="url(#paint6_linear_5687_1414)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_5687_1414" x1="21.4384" y1="45.516" x2="21.4384" y2="8.58587" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint1_linear_5687_1414" x1="14.9232" y1="45.2371" x2="14.9232" y2="8.18341" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint2_linear_5687_1414" x1="50.1573" y1="33.0437" x2="50.1573" y2="15.158" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint3_linear_5687_1414" x1="73.8537" y1="33.0476" x2="73.8537" y2="15.1557" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint4_linear_5687_1414" x1="97.6842" y1="33.0455" x2="97.6842" y2="15.1582" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint5_linear_5687_1414" x1="121.125" y1="33.0442" x2="121.125" y2="15.1554" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint6_linear_5687_1414" x1="142.949" y1="33.0442" x2="142.949" y2="15.1554" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <clipPath id="clip0_5687_1414">
        <rect width="152" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LogoSvg"
}
</script>