export default (baseClass, modifiers) => {
  const arrayClasses = [];
  arrayClasses.push(baseClass);
  if (modifiers)
    arrayClasses.push(
      ...modifiers
        .trim()
        .split(" ")
        .map((el) => (el.trim() ? `${baseClass}--${el}` : ""))
    );
  return arrayClasses;
};
